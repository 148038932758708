import React from 'react';
import { Typography } from '@mui/material';

// Custom component for the confirmation message
const ConfirmMessage = ({ selectedRows }) => {
  return (
    <div>
      {selectedRows.map((row, index) => (
        <div
          key={index}
          style={{
            marginBottom: '10px',
            fontFamily: 'Arial, Helvetica, sans-serif !important'
          }}>
          <Typography variant="body1" className="alert-meg">
            Page {index + 1}:
          </Typography>
          <div style={{ paddingTop: 2 }}>
            <Typography className="alertMeg" variant="body1">
              Allocated items : {row.NoOfItems}
            </Typography>
            <Typography className="alertMeg" variant="body1">
              Saved items : {row.SavedItems}
            </Typography>
          </div>
        </div>
      ))}
      <Typography className="alertmeg">Are you sure you want to approve?</Typography>
    </div>
  );
};

export default ConfirmMessage;
