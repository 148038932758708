import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, Grid, Typography, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import CloseIcon from '@mui/icons-material/Close';
import { CmpButtonOld, CmpInputNotion, CmpTypography } from '../../component/ToolBox/ToolBox';
import { flyerItemSearchAPI } from './action';

const columns = [
  { field: 'id', headerName: 'ID', width: 120 },
  { field: 'ItmCd', headerName: 'Item Code', width: 120 },
  { field: 'Name1', headerName: 'Item Name', width: 150 },
  { field: 'MainGrpName', headerName: 'MainGrpName', width: 100 },
  { field: 'SuppRefNo1', headerName: 'RefNo', width: 120 },
  { field: 'BaseUnitName', headerName: 'BaseUnitName', width: 120 },
  { field: 'BarCode', headerName: 'BarCode', width: 100 }
];

const ItemSearchModal = ({
  open,
  handleClose,
  anyDataLineItems,
  locData,
  setLineItems,
  xMediaQuery,
  vMediaQuery,
  aMediaQuery,
  uMediaQuery
}) => {
  const [anyData, setAnyData] = useState('');
  const [itemName, setItemName] = useState('');
  const [barCode, setBarCode] = useState('');
  const [refNo, setRefNo] = useState('');

  const [searchItemData, setSearchItemData] = useState([]);
  const [focusedRowIndex, setFocusedRowIndex] = useState(-1);
  const tableRef = useRef(null);

  useEffect(() => {
    setAnyData(anyDataLineItems);
  }, [anyDataLineItems]);

  useEffect(() => {
    if (open && anyData?.length > 3) {
      getSearchItems();
    } else {
      setSearchItemData([]);
      setItemName('');
      setBarCode('');
      setRefNo('');
    }
  }, [open]);

  const handleAnyData = (e) => {
    setAnyData(e.target.value);
    if (e.target.value === '') {
      setSearchItemData([]);
    }
  };

  const handleItemName = (e) => {
    setItemName(e.target.value);
  };
  const handleBarCode = (e) => {
    setBarCode(e.target.value);
  };
  const handleRefNo = (e) => {
    setRefNo(e.target.value);
  };

  const getSearchItems = async () => {
    const detailObj = {
      v_AnyData: anyData || '',
      v_Name1: itemName || '',
      v_Barcode: barCode || '',
      v_RefNo1: refNo || '',
      v_Loc: locData
    };

    const result = await flyerItemSearchAPI(detailObj);
    if (result && result.length) {
      // Add a unique id to each item
      const processedData = result?.map((item, index) => ({
        ...item,
        id: index + 1 // Ensure each row has a unique id
      }));
      setSearchItemData(processedData);
      setFocusedRowIndex(-1);
    } else {
      setSearchItemData([]);
    }
  };

  const handleRowClick = (params) => {
    const item = params.row; // Get the selected row data
    setLineItems((prevOrder) => ({
      ...prevOrder,
      itemCd: item?.ItmCd,
      itmName: item?.Name1
      //   B_UnitRate: item.SellPr1,
    }));
    handleClose();
  };

  const handleClear = () => {
    setAnyData('');
    setItemName('');
    setBarCode('');
    setRefNo('');
    setSearchItemData([]);

    setTimeout(() => {
      document.getElementById('anyData').focus();
    }, 500);
  };

  const handleCloseModal = () => {
    handleClear();
    handleClose();
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'ArrowDown' && searchItemData.length > 0) {
      setFocusedRowIndex((prevIndex) => {
        const nextIndex = prevIndex + 1 < searchItemData.length ? prevIndex + 1 : 0;
        return nextIndex;
      });
    } else if (e.key === 'ArrowUp' && searchItemData.length > 0) {
      setFocusedRowIndex((prevIndex) => {
        const nextIndex = prevIndex > 0 ? prevIndex - 1 : searchItemData.length - 1;
        return nextIndex;
      });
    } else if (e.key === 'Enter' && focusedRowIndex !== -1) {
      const selectedRow = searchItemData[focusedRowIndex];
      handleRowClick({ row: selectedRow });
    } else if (e.key === 'Enter') {
      if (anyData.length > 3) {
        getSearchItems();
      }
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      const virtualScroller = tableRef.current.querySelector('.MuiDataGrid-virtualScrollerContent');
      const rows = virtualScroller.querySelectorAll('[role="row"]');

      if (rows.length > 0) {
        rows.forEach((row, index) => {
          if (index === focusedRowIndex) {
            row.style.backgroundColor = '#d3d3d345';
            row.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            row.focus();
          } else {
            row.style.backgroundColor = ''; // Reset other rows' background
          }
        });
      }
    }
  }, [focusedRowIndex, searchItemData]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: vMediaQuery ? 330 : xMediaQuery ? 600 : 700,
          height:
            vMediaQuery && uMediaQuery
              ? '61vh'
              : xMediaQuery
              ? '63vh'
              : aMediaQuery
              ? '63vh'
              : '60vh',
          overflow: 'auto',
          bgcolor: 'background.paper',
          borderRadius: 3
        }}>
        <div className="notion-search">
          <Typography color="#fff" variant="subtitle1">
            Item Search
          </Typography>
          <CloseIcon onClick={handleCloseModal} sx={{ cursor: 'pointer' }} />
        </div>
        <Grid
          container
          sx={{ paddingX: vMediaQuery ? 2 : 4, paddingY: vMediaQuery ? 0.5 : '' }}
          spacing={vMediaQuery ? 0 : 3}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="notion-info">
              <Grid container spacing={2}>
                <Grid item xs={3} sm={4}>
                  <CmpTypography xcomponent={'span'} xText={'Any Data'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <TextField
                    fullWidth
                    name="anyData"
                    id="anyData"
                    variant="standard"
                    value={anyData}
                    onChange={handleAnyData}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={4}>
                  <CmpTypography xcomponent={'span'} xText={'Item Name'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xValue={itemName}
                    xType={'text'} // text/number/password
                    xName={'itmName'}
                    xOnChange={handleItemName}
                    xReadOnly={false}
                    xErrorMessage={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="notion-info">
              <Grid container spacing={2}>
                <Grid item xs={3} sm={4}>
                  <CmpTypography xcomponent={'span'} xText={'BarCode'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xValue={barCode}
                    xType={'text'} // text/number/password
                    xName={'barCode'}
                    xReadOnly={false}
                    xOnChange={handleBarCode}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={4}>
                  <CmpTypography xcomponent={'span'} xText={'RefNo'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xValue={refNo}
                    xType={'text'} // text/number/password
                    xName={'refNo'}
                    xOnChange={handleRefNo}
                    xReadOnly={false}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: vMediaQuery ? 1 : ''
              }}>
              <CmpButtonOld xLabel={'Load'} xvariant={'contained'} xonClick={getSearchItems} />

              <CmpButtonOld xLabel={'Clear'} xvariant={'contained'} xonClick={handleClear} />

              <CmpButtonOld xLabel={'Cancel'} xvariant={'contained'} xonClick={handleCloseModal} />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{ paddingX: vMediaQuery ? 2 : 4, paddingY: vMediaQuery ? 1 : 3 }}>
          {/* Data Grid */}
          <Grid item xs={12}>
            <Box sx={{ height: 300, width: '100%' }}>
              <DataGrid
                className="notion-grid"
                rows={searchItemData}
                columns={columns}
                pageSize={5}
                onRowClick={handleRowClick}
                ref={tableRef}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ItemSearchModal;
